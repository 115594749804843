/* Import */
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css');

/* Variable */
:root {
  --color-key: #2238CE;
  --color-base: #EDEDED;
  --color-text: #000;
  --font-size-default: 16px;
  --line-height: 1.66667em;
  --text-margin: 1em 0;
  --text-h1: 30px;
  --container-padding: 0 15px;
  --base-margin: 30px;
}

/* Common */
* {
  box-sizing: border-box;
  word-break: keep-all;
}
body {
  margin: 0;
  padding: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  color: var(--color-text);
  background-color: var(--color-base);
}
.container {
  padding: var(--container-padding);
  border-top: 1px solid transparent;
}

/* 230113 funny : 윗 첨자 위치와 크기 조절 */
sup {
    vertical-align:  0.9em;                             
    font-size: 0.4em;
}

/* Typo */
h1, h2, h3, h4, h5, h6 {
  line-height: 1.2em;
}
h3 {
  font-size: 24px;
  font-weight: 900;
}
h5 {
  font-size: var(--font-size-default);
}
p {
  font-size: var(--font-size-default);
  line-height: var(--line-height);
}
.lead {
  font-size: 1.25em;
  font-weight: 200;
}

/*  */
header {
  width: 100%;
  padding: 13.33334px 15px;
  position: fixed;
  top: 0;
  z-index: 99;
  border-bottom: 3px solid var(--color-key);
  background-color: var(--color-base);
  transition: border-bottom 0.3s ease-in-out;
}
  header > .container {
    padding: 0;
  }
  .menu-toggle {
    position: absolute;
    right: 15px;
    padding: 0;
    border: none;
    width: 41px;
    height: 30px;
    background-color: transparent;
  }
    .svg_menu_stroke {
      fill: var(--color-key);
    }
    .svg_menu_frame {
      fill: none;
    }
  .logo {
    display: inline-block;
    height: var(--base-margin);
  }
    .svg_logo {
      height: var(--base-margin);
    }
      .svg_logo_path {
        fill: var(--color-key);
      }
  .gnb {
    overflow-y: hidden;
    height: 0;
    width: 100%;
    padding-top: 0;
    transition: all 0.3s ease-in-out;
  }
  .gnb.show {
    height: 450px;
    transition: all 0.3s ease-in-out;
  }
    .gnb > ul {
      margin: 0;
      padding: 0;
    }
    .gnb > ul li {
      margin: 1em 0;
      list-style: none;
      text-transform: uppercase;
      font-size: 42px;
      letter-spacing: 0.18em;
      font-weight: 900;
      line-height: 0.7em;
      cursor: pointer;
    }
    .gnb-link {
      display: block;
      text-decoration: none;
      color: var(--color-text);
    }
    .gnb-link.active, .gnb > ul li a.active {
      color: var(--color-key);
    }
  header.scroll_intro {
    background-color: transparent;
    border-bottom-color: transparent;
  }
    header.scroll_intro .svg_menu_stroke,
    header.scroll_intro .svg_logo_path {
      fill: #fff;
    }
  header.gnb_show {
    border-bottom: calc(100vh - 450px) solid var(--color-key);
    transition: border-bottom 0.3s ease-in-out;
  }
  header .gnb-a, header .gnb-a:hover, header .gnb-a:visited {
    text-decoration: none;
    color: var(--color-text);
  }
  header.scroll_intro.gnb_show  {
    background-color: #000;
  }
    header.scroll_intro .gnb-a {
      color: #fff;
    }
      header.scroll_intro .gnb-a:hover, header.scroll_intro .gnb-a:visited {
        color: #fff;
      }
    header.scroll_intro .gnb-a.active {
      color: var(--color-key);
    }

section {
  margin: var(--base-margin) 0;
}
section.intro {
  margin: 0;
}
.container-fluid {
  overflow-x: hidden;
}

/* Contents */
main.main {
  scroll-snap-type: y mandatory;
}
.intro {
  background-color: #000;
  background-image: url(../img/logo_3d.png);
  background-position: -35% 67%;
  /* background-attachment: fixed; */
  background-size: 120%;
  background-repeat: no-repeat;
  height: 100vh;
}
  .intro > .container {
    background-image: url(../img/common/logo_onlytype.svg);
    background-position: 15px 59px;
    background-attachment: fixed;
    background-size: 111px;
    background-repeat: no-repeat;
    height: 100vh;
  }
  .intro .headcopy {
    position: absolute;
    top: 28%;
    font-size: var(--text-h1);
    color: #fff;
    margin: 0;
    line-height: 1.2em;
  }
.section-title {
  text-transform: uppercase;
  font-size: 42px;
  letter-spacing: 0.18em;
  font-weight: 900;
  line-height: 1em;
}
/* 230112 funny : 문장을 박스에 맞추기 */
.about p {
  margin-bottom: 2em;
  text-align: justify;                                    
}

.works .row {
  grid-template-columns: repeat(4, 1fr);
}
.works-rep {
  padding: 0;
}
  .works-rep--item {
    margin-bottom: 1.8em;
    list-style: none;
    border-bottom: 6px solid var(--color-key);
  }
  
    .works-rep--item a {
      color: var(--color-text);
      text-decoration: none;
    }
    .works-rep--item h5 {
      margin: 1.8em 0;
    }
  .works-rep--thumbnail {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    -moz-aspect-ratio: 100/72;
    aspect-ratio: 100/60;    
  }
  .works-rep-item--content {
    padding: 0;
    margin-bottom: 1.8em;
  }
  .works-rep-item--content li {
    list-style-type: none;
    margin-bottom: 0.8em;
  }
a {
    text-decoration: none;
    background-color: transparent;
    
    
}

.works-history {
  margin: 0 0;
  width: 100%;
  border-collapse: collapse;    
}
  .works-history thead th {
    text-transform: uppercase;
    text-align: left;
  }
  .works-history th, .works-history td {
    padding: 1.5em 0;
    border-bottom: 1px solid var(--color-text);
  }
  .works_history_link > a {
    color: var(--color-text);
  }
    .works_history_link > a:hover {
      color: var(--color-key);
      text-decoration: underline;
    }
.works-footer {
  margin: var(--base-margin) 0;
}
.btn-more {
  display: block;
  border: 1px solid var(--color-text);
  color: var(--color-text);
  font-size: var(--font-size-default);
  font-weight: bold;
  background-color: transparent;
  border-radius: 50%;
  width: 186px;
  height: 186px;
}
.centering {
  margin: 0 auto;
}

.member-list, .splide__track .splide__list {
  margin: calc(var(--base-margin) * 2) 0;
  padding: 0;
  white-space: nowrap;
  font-size: 0;
  /* overflow-x: auto; */
}
  .sections.members .splide__sr {
    display: none;
  }
  .member-item {
    display: inline-flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
    width: 256px;
    height: 256px;
  }
  .member-item-inner {
    color: var(--color-text);
    position: relative;
    height: 100%;
    width: 100%;
    text-decoration: none;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    cursor: pointer;
  }
  .member-item:hover .member-item-inner {
    transform: rotateX(180deg);
  }
  .member-item-front, .member-item-back {
    padding: 83px 65px;
    width: 100%;
    height: 100%;
    border: 1px solid var(--color-key);
    border-radius: 50%;
    color: var(--color-base);
  }
  .member-item-front, .member-item-back {
    position: absolute;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  .member-item-front {
    background-color: transparent;
    color: var(--color-text);
  }
  .member-item-back {
    transform: rotateX(180deg);
    background-color: var(--color-key);
    padding: 65px 65px;
  }
    .member-item-back p {
      white-space: normal;
      margin-top: 0;
    }
  .member-item-front::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    /* background-color: var(--color-base); */
    background-color: var(--color-key);
    /* opacity: 0.5; */
    width: 100%;
    height: 100%;
    content: "";
    clip-path: circle(50% at center);
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-position: 75px bottom;
    mask-position: bottom;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
    .member-item.keaton .member-item-front::before {
      -webkit-mask-image: url(../img/member_01.svg);
      mask-image: url(../img/member_01.svg);
    }

    .member-item.justa .member-item-front::before {
      -webkit-mask-image: url(../img/member_02.svg);
      mask-image: url(../img/member_02.svg);
    }

    .member-item.elly .member-item-front::before {
      -webkit-mask-image: url(../img/member_03.svg);
      mask-image: url(../img/member_03.svg);
    }

    .member-item.rio .member-item-front::before {
      -webkit-mask-image: url(../img/member_04.svg);
      mask-image: url(../img/member_04.svg);
    }

    .member-item.funny .member-item-front::before {
      -webkit-mask-image: url(../img/member_05.svg);
      mask-image: url(../img/member_05.svg);
    }

    .member-item.anna .member-item-front::before {
      -webkit-mask-image: url(../img/member_06.svg);
      mask-image: url(../img/member_06.svg);
    }
  .member-item h2, .member-item p {
    font-size: var(--font-size-default);
    font-weight: 900;
  }
  .member-item h2 {
    margin-top: 0;
  }
  .member-item p {
    margin-bottom: 0;
  }
.contact-form {
  margin-bottom: var(--base-margin);
}
.form-group {
  position: relative;
  margin-bottom: 30px;
  font-size: 18px;
}
  .form-group input:not(input[type=submit]), 
  .form-group textarea {
    width: 100%;
    background-color: rgba(0,0,0,0.05);
    border: none;
    outline: none;
    font-size: 18px;
    padding: 1.4em 1em 0.6em 1em;
    border-bottom-width: 5px;
    border-bottom-style: solid;
    border-bottom-color: transparent;
    transition: border-bottom-color 0.2s ease-in-out;
    color: var(--color-text);
  }
  .form-group input:not(input[type=submit]):focus-visible, 
  .form-group textarea:focus-visible {
    outline: none;
    border-bottom-color: var(--color-key);
    transition: border-bottom-color 0.2s ease-in-out;
  }
  .form-group label {
    position: absolute;
    top: 18px;
    left: 18px;
    font-size: 18px;
    /* opacity: 1; */
    transition: all 0.2s ease-in-out;
    color: var(--color-text);
    opacity: 0.5;
  }
    .form-group input:not(input[type=submit]):focus-visible + label, 
    .form-group textarea:focus-visible + label,
    .form-label.float-label {
      top: 9px;
      font-size: 9px;
      /* opacity: 0; */
      /* transition: opacity 0.3s ease-in-out; */
      transition: all 0.2s ease-in-out;
    }

.btn {
  font-size: 18px;
  padding: 1em;
  width: 100%;
  outline: none;
  border: none;
  color: #fff;
  background-color: var(--color-key);
}
.btn:disabled {
  background-color: #999;
}
.thankyou_message {
  padding: 1em;
  color: #fff;
  background-color: var(--color-key);
  text-align: center;
}
  .thankyou_message .lead {
    margin-bottom: 0;
  }
.contact-info {
  margin: 0;
  padding: 0;
  font-size: 18px;
}
  .contact-info dd {
    margin: 0 0 1em 0;
  }
  .contact-info a {
    color: var(--color-text);
  }
  .contact-info a:visited {
    color: var(--color-text);
  }
  dt[class^=contact-icon] {
    background-repeat: no-repeat;
    margin-bottom: 0.3em;
    text-indent: -9999px;
    background-color: var(--color-text);
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
    .contact-icon__address {
      /* background-image: url(../img/icon/building.svg); */
      -webkit-mask-image: url(../img/icon/building.svg);
      mask-image: url(../img/icon/building.svg);
    }
    .contact-icon__email {
      /* background-image: url(../img/icon/envelope.svg); */
      -webkit-mask-image: url(../img/icon/envelope.svg);
      mask-image: url(../img/icon/envelope.svg);
    }
    .contact-icon__github {
      /* background-image: url(../img/icon/github.svg); */
      -webkit-mask-image: url(../img/icon/github.svg);
      mask-image: url(../img/icon/github.svg);
    }
footer {
  margin: var(--base-margin) 0;
}
  .copyright {
    text-align: center;
    font-size: 12px;
    font-weight: 200;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }

/* Modal */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 101; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
  .modal__content {
    position: relative;
    background-color: var(--color-base);
    color: var(--color-text);
    margin: auto;
    width: 90%;
    padding: var(--container-padding);
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
  }
  .modal__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .modal__body {
    padding-bottom: 1em;
  }
  .modal__btn-close {
    border: none;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-color: var(--color-text);
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-image: url(../img/icon/close.svg);
    mask-image: url(../img/icon/close.svg);
  }
  .modal__body .history-date {
    display: block;
    margin-top: 0.5em;
  }
  /* Add Animation */
  @-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
  }

  @keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }

@media (min-width: 1024px) {
  header {
    border: none;
  }
    header > .container {
      display: flex;
      justify-items: center;
      justify-content: space-between;
      align-items: center;
    }
  .menu-toggle {
    display: none;
  }
  .gnb {
    width: auto;
    height: auto;
  }
    .gnb > ul li {
      display: inline-block;
      margin: 0;
      margin-left: 25px;
      line-height: 30px;
      font-size: var(--font-size-default);
    }

  .section-title:not(.no-sticky) {
    margin-top: 0;
    position: sticky;
    top: 52px;
  }
  .lead {
    margin-top: 0;
  }
  .section-body {
    grid-column: 3 / 6;
  }
  .intro .headcopy {
    font-size: 50px;
    top: 37%;
  }
  .works-history th:first-child {
    width: 20%;
  }
  .modal__body .history-date {
    width: 155px;
    display: inline-block;
    margin-top: 0;
  }
}

.list-style-none {
  padding: 0;
}
  .list-style-none li {
    list-style: none;
    line-height: 1.66667em;
  }
@media (min-width: 1366px) {
  :root {
    --font-size-default: 18px;
    --container-padding: 0 25px;
    --base-margin: 40px;
  }
  header {
    padding: var(--base-margin) 25px;
  }
  .intro {
    background-position: 115% 67%;
    background-size: 77%;
  }
    .intro > .container {
      background-position: 25px 92px;
      background-size: 147.1791px;
    }
    .intro .headcopy {
      top: 42%;
    }
  .row {
    display: grid;
    grid-gap: 15px;
    grid-template-rows: 4fr;
    grid-template-columns: repeat(4, 1fr);
  }
  .section-title:not(.no-sticky) {
    top: 120px;
  }
  .lead {
    grid-column: 2/5;
  }
  .col-1-title {
    grid-column: 2/3;                          /* 230112 funny : 제목을 길게 하기 위해서 변경 // 원본 -> grid-column: 2/3; */
  }
  .col-2-text {
    font-size: 1.25em; /* font-size: 1.114em;   */                     /* 230113 funny : 자간 간격이 너무 큰거를 보완하기 위함 */
    grid-column: 3/5
  }
  .member-list, .splide__track .splide__list {
    margin: calc(var(--base-margin) * 2) 0;
  }
    .member-item {
      width: 384px;
      height: 384px;
    }
    .member-list a {
      /* padding: 138px 100px; */
    }
    .member-item-front, .member-item-back {
      padding: 138px 100px;
    }
  .contact-form {
    grid-column: 2/4;
  }
  .sections.works {
    font-size: var(--font-size-default);
  }
}
@media (min-width: 1440px) {
  :root {
    --base-margin: 60px;
  }
  h3 {
    font-size: 36px;
  }
  header > .container {
    padding: 0 25px;
  }
  .intro {
    background-position: 115% 67%;
    background-size: 77%;
  }
    .intro > .container {
      background-attachment: scroll;
      background-position: 25px 138px;
      background-size: 223px;
    }
    .intro .headcopy {
      font-size: 60px;
    }
  .gnb > ul li {
    margin-left: 50px;
  }
  .container {
    width: 1390px;
    margin: 0 auto;
  }
  .container-fluid {
    width: 100%;
    overflow-x: hidden;
  }
  /* .container-fluid .member-list, .container-fluid .splide__track .splide__list {
    margin: calc(var(--base-margin) * 2) 0;
  } */
  .row {
    grid-gap: 50px;
    grid-template-rows: 5fr;
    grid-template-columns: repeat(5, 1fr);
  }
  .section-title {
    font-size: 84px;
  }
  .lead {
    font-size: 1.66667em;
    grid-column: 3/6;
  }
  /* 230112 funny : 문장을 박스에 맞추기 */
  .about p {    
    margin-bottom: 0;
    text-align: justify;
  }
  .col-1-title {
    grid-column: 3/4;                         /* 230112 funny : 제목을 길게 하기 위해서 변경 // 원본 -> grid-column: 3/4; */
    margin: 0;
  }
  .col-2-text {
    font-size: 1.25em; /* font-size: 1.114em;  */                      /* 230113 funny : 자간 간격이 너무 큰거를 보완하기 위함 */
    grid-column: 4/6;
    margin: 0;
  }
  .contact-form {
    grid-column: 3/5;
  }

  .section-title:not(.no-sticky) {
    top: 180px;
  }
}
@media (min-width: 1920px) {
  .container {
    width: 1740px;
    margin: 0 auto;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-base: #1E1C22;
    --color-text: #eee;
    --color-key: #1289ff;
  }
  .form-group input:not(input[type=submit]), 
  .form-group textarea {
    background-color: rgba(255,255,255,0.05);
  }
}